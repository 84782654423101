@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

* {
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "DM Sans", sans-serif;
}

ul {
    list-style: none;
    padding: 0;
}

img {
    width: 100% !important;
}

:root {

    --theme-color-green: #51BF65;
    --theme-color-orange: #F24822;
    --theme-color-black: #131313;
    --theme-color-grey: #BABABA;
    --theme-color-white: #FFFFFF;
    --theme-color-in-black: #171717;
    --theme-color-in-grey: #E7E7E7;
    --theme-color-table-grey: #E6E6E6;
    --theme-color-in-reddish: #F24822;
    --semi-transparent-theme-color: rgba(231, 231, 231, 0.5);

}

p,
span {
    font-size: 14px !important;

}

a {
    text-decoration: none !important;
    color: var(--theme-color-in-black) !important;
}

/* custom class */
.tetxdark_under {
    font-weight: 600 !important;
    text-decoration: underline !important;
    font-size: 14px !important;
    cursor: pointer;

}

.colored_text {
    color: var(--theme-color-in-reddish);
}

.icon_dash {
    width: 15px !important;
}

.cancel_icon {
    width: 25px;
}

.cus_text {
    color: var(--theme-color-in-black) !important;

}

.til_s_black {
    font-weight: 500 !important;
    color: var(--theme-color-black);
}

.text_left {
    text-align: left !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

/* custom class */
/* sidebar */
.side-nav {
    width: 300px;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    background-color: var(--theme-color-white) !important;
    border-right: 2px solid var(--unnamed-color-cb9b51);
    padding: 50px 0 !important;


}

.image_logo {
    width: 100px !important;
}

.side-nav1 {
    display: none;

}

.side-nav ul {
    padding-left: 1rem !important;
}

.sidenavimg {
    width: 20px;
    height: 25px;
}
.dropdown-container {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background: white;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.side-menu {
    margin-top: 50px;
}

.side-menu li a {
    cursor: pointer;
    padding: 10px 32px;
    font-size: 18px;
    color: #8892a6 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.side-menu li:last-child {
    /* position: absolute !important;
    bottom: 24px !important; */
}

.Verified {
    color: #51BF65 !important;
    font-weight: 500 !important;
}

.pending {
    color: #D5A254 !important;
    font-weight: 500 !important;
}

.error {
    color: #e32b2b !important;
    font-weight: 400 !important;
}

.side-menu li ul {
    max-height: 0;
    overflow: hidden;
    padding-left: 1rem;
}

.submenu {
    max-height: 500px !important;

}

.side_menutext {
    color: var(--unnamed-color-cb9b51);
}


.downArrow {
    width: 20px;
    transform: rotate(0deg);
    transition: transform .5s ease;
}

.upArrow {
    transform: rotate(180deg);
}


.side-menu li a.active {
    color: var(--theme-color-black) !important;

}

.side-menu li ul a.active {
    color: var(--theme-color-black) !important;


}

.details_tile {
    padding: 10px !important;
    background: var(--theme-color-white) !important;
    border-bottom: 1px solid var(--theme-color-in-grey);

}

.active {
    border-right: 5px solid var(--theme-color-green) !important;
    border-radius: 2px;

}

.side-menu p {
    margin-bottom: 0 !important;
    font-weight: 600 !important;

}

#hamburger-1 {
    display: none;
}

/* sidebar */
/* header */
.header {
    height: 80px;
    padding: 10px 25px 10px 10px;
    width: 100%;

}

.header,
.header_content {
    align-items: center;
    display: flex;
}

.header_content {
    justify-content: space-between;
    width: 100% !important;
}

.user_profile {
    background: var(--theme-color-white) !important;
    padding: 12px 25px;
    border-radius: 25px !important;
    box-shadow: 0px 8px 10px 0px #5555550D;


}

.header_content p {
    margin-bottom: 0 !important;
}

/* header */
/* .page-container */
.page-container {
    height: 100vh;
    padding: 40px !important;
    overflow-y: auto;
    width: calc(100% - 300px);
    background: var(--semi-transparent-theme-color) !important;
}

.rdw-editor-main {
    
    border:  1px solid var(--theme-color-table-grey);
    padding: 6px 12px;
    width: 100% !important;
    height: 150px !important;
}
.rdw-dropdown-selectedtext {
    width: 20px !important;
}
.rdw-dropdown-selectedtext {
    justify-content: space-between !important;

}
.rdw-dropdown-carettoopen {
    right: -50% !important;
}
.rdw-dropdown-carettoclose
{
    right: -50% !important;  
}
/* .page-container */
/* forms */
input[type="radio"],
input[type="checkbox"] {
    accent-color: var(--theme-color-in-black) !important;
    cursor: pointer !important;
    block-size: 15px;
    inline-size: 15px;
}

.search {
    align-items: center;
    background-color: #fff;
    border-radius: 25px;
    display: flex;
    padding: 10px 15px;
}

.search_btn {
    background: #fff !important;
    border: none;
    color: #333;
    columns: var(--unnamed-color-dddddd);
    padding: 0px 10px;

}

.serch_input {
    width: 90% !important;
    border: 0 !important;
    /* background: #F6F6F6 !important; */
    border-radius: 15px 50px 30px 5px;
}

.serch_input:focus {
    outline: none !important;
}

.error {
    text-align: left !important;
    color: red !important;
}

.form_default {
    width: 100% !important;
    text-align: center;
}

.form_default label {
    margin-bottom: 0.25rem;
    display: block;
    font-size: 14px !important;
    text-align: left !important;
    font-weight: 400;
}

.gl-form-asterisk {
    background-color: inherit;
    color: #e32b2b;
    padding: 0;
    padding-left: 3px;
}

.gl-form-asterisk:after {
    content: "*";
}

.form_default input:not([type="checkbox"]):not([type="radio"]),
.form_default textarea,
.form_default select {
    width: 100% !important;
    border: 1px solid var(--theme-color-table-grey);
    padding: 7px 19px;
}

.form_default select {
    border: 0 !important;
}

.react-select-33-input input {
    padding: 0 !important;
    outline: none !important;
}

.css-8iih9-control {
    outline: none !important;
    border: 1px solid var(--theme-color-table-grey) !important;
}

.css-1gny57x-control:hover {
    outline: none !important;
    color: var(--theme-color-in-grey) !important;
}

.form_default input:focus,
.form_default textarea:focus,
.form_default select:focus {
    outline: none !important;
}


.custom-tab {
    color: #A4A4A4;
}

.active-settings {
    color: var(--theme-color-black) !important;
}

/* forms */

/* tables */

.custom_tile_tablesetting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--theme-color-white) !important;
    padding: 16px 24px ;
    gap: 0px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    color: rgba(23, 23, 23, 1);
}


.form-check-input:checked {
    background-color: black !important;
    border-color: black !important;
}

.form-check-input:focus {
    border-color: black !important;
    outline: 0;
    box-shadow: none !important;
}
.form-switch .form-check-input:focus {
    display: none !important;
}

.custom_tile_table {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 0 !important;
    margin: 15px 0;
    background-color: var(--theme-color-white) !important;
    color: #828282 !important;
}

.custom_tile_table .tile:last-child {
    width: 100% !important;
    margin-top: 5px !important;
    display: flex;
    justify-content: space-between;
}

.default_table {
    overflow: auto !important;
}

.default_table table {
    background: var(--theme-color-white) !important;
    min-width: max-content !important;

}

.default_table table thead tr th {
    font-weight: 500 !important;
    padding: 10px;

}

.default_table table tbody tr td {
    padding: 10px;
}

.default_table table tr {
    box-shadow: 0px 2px 2px var(--theme-color-table-grey);
}

/* tables */

/* custom scroll */
.loadingSpinnerOverlay {
    position: fixed;
    /* Fixed positioning to cover the entire viewport */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: no-drop;
    /* Semi-transparent black background */
    z-index: 9999;
    /* Place the overlay above other content */
}

.loadingSpinnerContainer {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Or any color you want for the spinner container */
    border-radius: 8px;
}

.loadingSpinner {
    width: 48px;
    height: 48px;
    border: 6px solid;
    border-color: var(--theme-color-green) transparent var(--theme-color-black);
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
}




@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* login */
.log_scr_logo {
    width: 200px;
}

.login_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;

}


.login_block {
    border: 1px solid var(--theme-color-black);
    padding: 50px !important;
    text-align: center !important;
}

.default_btn {
    background-color: var(--theme-color-black) !important;
    color: var(--theme-color-white) !important;
    width: 100%;
    text-align: center;
    padding: 10px !important;
    font-size: 14px !important;

}


/* login */
/* buttons */

.default_btn {
    background-color: var(--theme-color-black) !important;
    color: var(--theme-color-white) !important;
    width: 100%;
    text-align: center;
    padding: 10px !important;
    font-size: 14px !important;

}

.add_btn {

    width: 100%;

    font-weight: 500;
    text-align: center;
    padding: 10px !important;
    font-size: 14px !important;
    border-radius: 8px;
    border: 0;
}

.cancelClass {
    color: var(--theme-color-green) !important;
    border: 1px solid var(--theme-color-green) !important;
    background: inherit !important;
}

.colorClass {
    color: var(--theme-color-white) !important;
    border: 1px solid var(--theme-color-green) !important;
    background: var(--theme-color-green) !important;
}

.rejectcolor{
    color: var(--theme-color-white) !important;
    border: 1px solid red !important;
    background: red !important;
}

.edit_delete {
    width: 100%;
    text-align: center;
    min-width: 50px !important;
    padding: 10px !important;
    font-size: 14px !important;
    border-radius: 20px;
    font-weight: 400;
    border: 1px solid var(--theme-color-table-grey);
    background-color: var(--theme-color-white);
}

.edit_delete:hover {
    background-color: var(--theme-color-table-grey);
}

.icon_btn {
    display: flex;
    justify-content: center;
    align-items: baseline;
    text-align: center !important;
    background: var(--theme-color-green) !important;
    color: var(--theme-color-in-black) !important;
    padding: 15px !important;
    cursor: pointer !important;
    margin-bottom: 0 !important;

}

.icon_text p {
    margin-bottom: 0 !important;

}

.border_btn {
    background-color: var(--theme-color-white) !important;
    color: var(--theme-color-black) !important;
    width: 100%;
    text-align: center;
    padding: 10px !important;
    font-size: 14px !important;
}

/* buttons */

/* .accordion- */
.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-item:first-of-type > .accordion-header .accordion-button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.accordion-button:not(.collapsed) {
    background-color: transparent !important;
    color: #131313 !important;
    box-shadow: none !important;
}

/* .accordion-bs */
/* comon modal */
.tc_height {
    height: 90vh !important;

}

.width-40 {
    width: 43% !important;
}

.width-75 {
    width: 75% !important;
}

.width-45 {
    width: 45% !important;
}

.width-50 {
    width: 48% !important;
}

#custom-modal {
    margin: 0 auto;
    width: 100%;
    /* height: 90%; */
    border: 0;
    outline: none;
    padding: 3rem 4rem;
    max-height: 100vh !important;
    background-color: var(--theme-color-white) !important;
    overflow: auto !important;


}

.boder_box{
    border: 1px solid rgba(155, 155, 155, 1);
    background: rgba(255, 255, 255, 1);

}
.vehicalno{
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}
.borderbody{

    /* width: 100% !important; */
    border: 1px solid var(--theme-color-table-grey);
    padding: 16px 19px;
}
.cross_sign {
    cursor: pointer !important;
}
.vehicalid{
    margin-bottom: 0px !important;
}
.vehicalno{
    padding-bottom: 14px;
}

/* common modal */

/* steeper */
.MuiStepLabel-root {
    padding: 20px !important;
}

.MuiStepIcon-active {
    color: rgba(0, 0, 0, 0.38) !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: var(--theme-color-in-black) !important;
}

/* steeper */

/* dashboard */
.custom_card {
    padding: 30px;
    color: var(--theme-color-in-black);
    border-radius: 16px;
    font-size: 20px !important;


}


.counter span {
    font-weight: 500;
    font-size: 50px !important;
}

/* dashboard */


/* mdeia queries */
@media(max-width:1220px) {
    .page-container {
        width: 100%;
        position: relative;
        /* / overflow-y: hidden; / */

    }

    .page-container1 {
        width: 100%;
        position: relative;
    }



}

@media (max-width: 1000px) {


    #hamburger-1 {
        display: block;
    }

    .page-container {
        padding: 20px !important;
    }

    .side-nav {
        z-index: 1000;
        position: fixed;
        width: 100% !important;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 0.5s ease;
        padding: 50px 0 !important;
    }

    #custom-modal {
        padding: 2rem;
    }

    .width-40 {
        width: 80% !important;
    }

    .width-75 {
        width: 80% !important;
    }

    .width-50 {
        width: 80% !important;
    }

    .default_table table thead tr th {
        padding: 5px !important;
        font-size: 15px !important;

    }

    .default_table table tbody tr td {
        padding: 5px !important;
        font-size: 14px !important;
    }

    .custom_tile_table {
        display: block !important;
        padding: 15px !important;
    }


}

/* mdeia queries */

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--theme-color-in-reddish);;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--theme-color-green);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--theme-color-green);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }



  /* General badge styling */
.status-badge {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 0.9em;
    color: #fff;
    font-weight: bold;
}

/* Color coding for specific statuses */
.status-badge.ignition-on {
    background-color: #4caf50; /* Green for "Ignition On" */
}
.status-badge.idle {
    background-color: #ffa726; /* Orange for "Idle" */
}
.status-badge.ignition-off {
    background-color: #f44336; /* Red for "Ignition Off" */
}

/* Toggle switch styling */
.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

.anubhavdiasbled {
    opacity: 50%;
}

input:checked + .slider:before {
    transform: translateX(14px);
}
.slider.round
.nodataimage{
    height: 100px;
    width: auto !important;
}


.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 500px;
}

.popup-actions button {
    margin: 5px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.popup-actions button:first-child {
    background-color: #51BF65;
    color: white;
}

.popup-actions button:last-child {
    background-color: #F24822;
    color: white;
}
